.card[data-card-height="cover-full"],
.card[data-card-height="cover"] {
  height: 100vh;
}

.card[data-card-height="cover-card"] {
  height: calc(100vh - 200px);
}

#form1a-select {
  border-color: rgba(255, 255, 255, 0.1) !important;
  font-size: 15px;
  color: #fff !important;
}

.custom_play_btn {
  border: 0;
  width: 100px;
  border-radius: 4px;
  padding: 3px 5px;
  margin: 8px 7px 12px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  box-shadow: 0 1px 3px rgb(0, 0, 0, 0.5);
  background: #10396A !important;
  border: 2px solid #E6960C;
}

.balance_wrapper {
  font-size: 13px;
  font-family: inherit;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
}

.balance_wrapper .links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  margin: 0px;
  width: 30%;
  padding-right: 1px solid;
}

.balance_wrapper .pbalance {
  width: 70%;
}

.home_balance {
  overflow: hidden;
}

.balance_btns a {
  text-align: center;
  font-size: 11px;
  padding: 5px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  color: black;
}

.balance_btns a .main_rounded {
  width: 45px;
  height: 45px;
  padding: 10px;
  display: block;
  margin: 0 auto 6px;
  background: #e9e6f6;
  overflow: hidden;
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, .3);
  box-shadow: 0 5px 5px rgba(0, 0, 0, .3);
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.links a {
  background: transparent;
  border: none;
  position: relative;
  width: auto;
}

.links a img {
  position: relative;
  width: 80%;
  height: auto;
}

.links a span {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  text-align: center;
  color: #223651;
}

.pbalance {
  overflow: hidden;


}
.balance-container {
  background-image: linear-gradient(to bottom right, #b570e5, #5c2499) !important;
  border-radius: 5px;
  align-items: center;
}

.balance_btns {
  padding-bottom: 5px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  border-left: 1px solid #d7d7d7;
}

.balance_btns .btns {
  text-align: center;
  width: 100%;
  margin-top: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.balance_btns .btns img {
  margin-right: 5px;
}

.balance_btns .btns i {
  margin: 4px -23px;
  position: absolute;
  font-size: 18px;
}

.page-title .logo {
  height: 46px;
  width: 100%;
  margin: 5px 10px;
}

.currency_icon img {
  height: 27px;
  margin: 10px 0 0 10px !important;
}

.btn_green {
  color: #212529 !important;
  background-color: white;
  border-radius: 4px !important;
  border: 0;
  box-shadow: 0 1px 3px rgb(0, 0, 0, 0.5);
  font-size: 15px !important;
  line-height: 20px !important;
  padding: 8px 0;
  font-weight: bold;
  text-transform: uppercase;
}

.register_btn {
  width: 150px;
  background: #E6960C;
  border-color: #E6960C;
  border-radius: 4px;
}

.badge_anima {
  background: #f38120;
  margin: 3px -40px;
  position: absolute !important;
  color: #fff !important;
  line-height: 20px;
  width: 20px;
  text-align: center;
  border-radius: 10px;
  top: 5px;
  right: 50%;
  font-weight: 700 !important;
  font-size: 12px !important;
  opacity: 1 !important;
}

.main_pulse {
  animation: pulse 1s infinite ease-in-out alternate;
}

/* marquee {
  background-image: url("../src/image/marquee.jpg");
  font-size: 11px;
  padding: 7px;
  color: #A9A9A9;
  letter-spacing: 0.5px;
  height: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
  line-height: 1;
  background-size: 100% 100%;
  z-index: 2;
  position: relative;
  overflow: hidden;
} */

#settings {
  padding: 10px;
  font-size: 18px !important;
  font-weight: 700 !important;

}

.setting_head {
  color: #D4A948 !important;
  border-bottom: 2px solid;
  padding-bottom: 10px !important;
}

#settings a {
  padding: 15px 0 !important;
  border-bottom: 1px solid;
  color: #7e41b6;
  display: block;
  font-size: 15px;
}

.color_yellow {
  color: #D4A948 !important;
}

#deposit_cards {
  padding: 15px;
  overflow: hidden;
}

#deposit_cards .pay_menu {
  background: transparent;
  display: block;
  padding: 7px;
  border-radius: 4px;
}

#deposit_cards .pay_menu img {
  width: 100%;
}

.custom-icon {
  display: flex;
  width: 65%;
  align-items: center;
  justify-content: space-between;
}

.custom-icon i {
  font-size: 22px;
  color: gray;
  margin-right: 10px;
}

/* .custom_title {
  margin-top: 0px !important;
  padding: 20px !important;
  padding-top: 20px !important;
} */

.announcement {
  padding: 10px;
  font-size: 12px;
  color: #213034;
  display: flex;
  background: #efedfd;
  border-bottom: 1px solid #d9d0f5;
  align-items: center;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.announcement>div {
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.announcement img {
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  -webkit-background-size: 18px;
  -o-background-size: 18px;
  background-size: 18px;
  display: block;
  margin-right: 5px;
}

.announcement .running_text {
  padding: 0 0 0 5px;
  width: 220px;
  color: #55289a;
}

.custom_title {
  background-image: url(./images/sidepush-header1.jpg);
  width: 230px;
  height: 184px;
  background-position: top center;
  background-repeat: no-repeat;
  -webkit-background-size: 280px 184px;
  -o-background-size: 280px 184px;
  background-size: 230px 155px;
}

.custom_title p {
  font-size: 11px !important;
  font-weight: 400 !important;
}

.custom_title div {
  padding: 39px 0 10px;
  margin-left: -7px;
}

.custom_title button {
  padding: 30px 0 10px;
  border: none;
  outline: none;
  background: transparent;
}

.btn_gold {
  padding: 10px 0;
  background-image: url(./images/btn-login.png);
  -webkit-background-size: 156px 50px;
  -o-background-size: 156px 50px;
  background-size: 156px 50px;
  width: 156px;
  height: 50px;
  display: block;
  text-align: center;
  color: inherit;
  margin: 5px auto;
  font-weight: bold;
}

.invalid-feedback {
  display: block;
  font-size: 13px;
  color: red !important;
}

.valid-feedback {
  display: block;
}

.text-red {
  color: red !important;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.ewallet-item {
  border: 1px solid #ced4da;
  background-color: transparent !important;
}

.fixed {
  position: fixed;
}

.w-screen {
  width: 100vw;
}

.h-screen {
  height: 100vh;
}

.top-0 {
  top: 0px !important;
}

.left-0 {
  left: 0px !important;
}

.zIndex100 {
  z-index: 1001 !important;
}

.bg-01 {
  background: #00000040 !important;
}

.w-10 {
  width: 40px;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.gap-1 {
  gap: 4px;
}

.ml-1 {
  margin-left: 1rem;
}

.text-white {
  color: white;
}

.text-black {
  color: black;
}

.items-center {
  align-items: center;
}

.mb-3 {
  margin-bottom: 12px !important;
}

.css-13cymwt-control {
  border-radius: 0 !important;
  height: 50px;
}

.css-t3ipsp-control {
  border-radius: 0 !important;
  height: 50px;
}

.banks-container {
  padding: 1rem;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.banks-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.banks-wrap .bank-item {
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 5px;
  padding: 10px;
}

.banks-wrap .bank-item img {
  filter: grayscale(100%);
  width: 50px;
  height: 50px;
}

.selected-bank {
  color: #e4ae60 !important;
  font-weight: bold;
}

.selected-bank img {
  filter: grayscale(0) !important;
}

.banks-info {
  padding: 1rem;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
}

.banks-info table {
  width: 100%;
}

.copy-btn {
  background-image: linear-gradient(to bottom right, #b570e5, #5c2499) !important;
  color: #e8b22a !important;
  color: white;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  text-shadow: white;
  padding: 0 5px;
  border-radius: 5px;
}

.max-withdraw-btn {
  background-image: linear-gradient(to bottom right, #b570e5, #5c2499) !important;
  color: #FFF;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 5px;
  padding: 5px 10px !important;
}

.position-relative {
  position: relative !important;
}

.pink-bg {
  background-image: linear-gradient(to bottom right, #b570e5, #5c2499) !important;
}

.flex-col {
  flex-direction: column;
}

.p-10 {
  padding: 10px;
}

.w-half {
  width: 50%;
}

.font-bold {
  font-weight: bold;
}

.text-sm {
  font-size: 0.875rem;
  /* 14px */
  line-height: 1.25rem;
  /* 20px */
}

.text-2xl {
  font-size: 1.5rem;
  /* 24px */
  line-height: 2rem;
  /* 32px */
}

.cash-border {
  border-right: 1px solid white;
}

.rounded-lg {
  border-radius: 0.5rem;
  /* 8px */
}

.w-75 {
  width: 75% !important;
}

.w-25 {
  width: 25% !important;
}

.m-0 {
  margin: 0 !important;
}

.game-btn-inactive {
  background-image: linear-gradient(to bottom right, white, #e4dae9);
  color: #5c2499;
  border: 1px solid #d9d0f5;
  width: fit-content;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 15px;
  border-radius: 5px;
}

.game-btn-active {
  background-image: linear-gradient(to bottom right, #b570e5, #5c2499);
  color: #e8b22a;
  border: 1px solid #d9d0f5;
  width: fit-content;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 15px;
  border-radius: 5px;
}

.cash-custom-icon {
  background-image: url('/images/cash.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0px;
  width: 45px;
  height: 45px;
}

.chips-custom-icon {
  background-image: url('/images/chips.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0px;
  width: 45px;
  height: 45px;
}

.m-auto {
  margin: auto;
}

.download-app-info {
  font-size: 15px;
}

.download-app-info .img-wrap {
  margin-bottom: 30px;
  border-radius: 10px;
  border: 4px solid #b570e5;
  padding: 5px;
  overflow: hidden;
}

.download-app-info .img-wrap div {
  height: 130px;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
}

.download-app-info .img-wrap img {}

.download-app-info .balance-wrap {
  margin-top: 30px;
  color: white;
  background-color: #b570e5;
  font-weight: bold;
  text-align: center;
  border-radius: 20px 20px 0 0;
  font-size: 1.1em;
  margin-bottom: 20px;
}

.download-app-info .accountInfo-wrap {
  padding: 0 10px;
}

.download-app-info span {
  font-weight: bold;
}

.clipboard-wrap {
  padding: 5px 10px;
  width: 150px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 13px !important;
}

.download-apk-btn {
  border: 1px solid #b570e5;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
  font-size: 12px;
}

.play-btn {
  border: 1px solid #b570e5;
  background-color: #b570e5;
  color: white;
  width: 100%;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
  font-size: 15px;
}

.w-full {
  width: 100%;
}

.bg-silver {
  background-color: silver;
}

.text-12 {
  font-size: 12px !important;
}

.my-10 {
  margin: 10px 0;
}

.download-app-info a {
  text-decoration: none !important;
  counter-reset: black;
}

.h-scroll {
  overflow: scroll;
  height: calc(100vh - 105px);
}