.game-accounts-container {
    padding: 1rem;
  }
  
  .game-accounts-container .account-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
    border-bottom: 1px solid #ccc;
    padding-bottom: 1rem;
  }
  
.account-item .account-content {
    width: 100%;
    height: 100px;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .account-content p {
    font-size: 1rem;
    width: 100%;
    font-weight: 500;
    margin-bottom: 0;
  }
  .account-content .pass {
    margin-top: 0;
    border: none;
    width: 70%;
    outline: none;
    background-color: white;
    margin-top: 4px;
    font-weight: 500;
    color: black;
  }
  
  .account-content .pass input {
    background-color: transparent;
    color: black;
  }
  .account-content .ant-input-password.pass{
      border-color: #4096ff;
      border-inline-end-width: 1px;
      z-index: 1;
      box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  }
  
  .account-item h3 {
    width: 50%;
    text-align: right;
    font-weight: bold;
    font-size: 1.1rem;
  }
  .account-item h3 > span {
    font-size: .8rem;
    font-weight: 300;
  }
  .account-item img {
    width: 100px;
    height: 100px;
    border-radius: 1rem;
  }